import ElementInputText                   from "$elements/ElementInputText";
import ElementPagination                  from "$elements/ElementPagination";
import ElementTable                       from "$elements/ElementTable";
import LayoutTable                        from "$layouts/LayoutTable";
import {IPagedRequest}                    from "$models/IPagedRequest";
import IPagedResponse                     from "$models/IPagedResponse";
import UseQueryResult                     from "$types/UseQueryResult";
import {searchParams, setSearchParams}    from "$utils/url";
import classNames                         from "classnames";
import * as React                         from "react";
import {useEffect, useState}              from "react";
import {useTranslation}                   from "react-i18next";
import styled                             from "styled-components";
import ETicketState                       from "$types/ETicketState";
import {TicketStateHoverBackgroundColors} from "$store/constants/TicketStateBackgroundColors";
import {LinearProgressHolder}             from "$elements/styled";
import useDebounce                        from "../../hooks/useDebounce";

interface ITbodyProps {
  state?: ETicketState
}

const hoverBackgroundColors = TicketStateHoverBackgroundColors;

interface IProps<T, S> {
  request: (params: IPagedRequest & S) => UseQueryResult<IPagedResponse<T>>,
  header?: React.ReactNode,
  additionalControls?: React.ReactNode | ((params: object) => React.ReactNode),
  stickyFirstColumn?: boolean,
  onLoadingChange?: (loading: boolean) => void,
  filters?: (onUpdateFilters: (filterParams: S) => void) => React.ReactNode,
  rowRender: (data: T, rowIndex: number) => React.ReactNode,
}

const StyledTbody = styled.tbody`
  & tr {
    &:hover {
      & span[id="state"] {
        background-color: ${({state}: ITbodyProps) => state ? hoverBackgroundColors[state] : ''};
      }
    }
  }
`

export default function ComponentTable<T, S extends object>({
  header,
  rowRender,
  additionalControls,
  onLoadingChange,
  request,
  filters,
  stickyFirstColumn = false,
  state,
}: IProps<T, S> & ITbodyProps) {
  const {
    page:  queryPage = '1',
    limit: queryLimit = '15',
    query: argQuery = '',
    ...    args
  } = searchParams();
  const {t} = useTranslation();
  const [page, setPage] = useState(queryPage ? parseInt(queryPage) : 1)
  const [limit, setLimit] = useState(queryLimit ? parseInt(queryLimit) : 15);
  const [query, setQuery] = useState<string | null | undefined>(argQuery);
  const [additionalParams, setAdditionalParams] = useState<S>(args as S);

  const {data, isLoading, isFetching, isError, error} = request({
    page,
    limit,
    query,
    ...additionalParams,
  });

  useEffect(() => {
    if (onLoadingChange) {
      onLoadingChange(isLoading);
    }
  }, [isLoading])

  useEffect(() => {
    if (additionalParams) {
      setPage(1);
      setLimit(15);
      setQuery('');
    }
  }, [additionalParams]);

  useEffect(() => {
    setSearchParams({
      ...additionalParams,
      page,
      limit,
      query,
    }, true);
  }, [additionalParams, page, limit, query]);

  const onPageChange = (page: number) => {
    setPage(page);
  }

  const updateAdditionalParams = (params: S) => {
    setAdditionalParams(params);
  }

  const onLimitChange = (limit: number) => {
    setLimit(limit);
  }

  const handleValueChange = useDebounce((value) => {
    setQuery(value);
  }, 1000);

  const pagination = <ElementPagination
    page={page}
    pages={data?.pages ?? 0}
    itemsCount={data?.count ?? 0}
    itemsPerPage={limit}
    onPageChange={onPageChange}
    onLimitChange={onLimitChange}
  />;

  return isError ? <div>{(error as { data: { message: string } })?.data?.message}</div> : <LayoutTable
    filters={<div className="flex v-centered start">
      <div className="flex-item fixed-size mgr-1">
        {filters && filters(updateAdditionalParams)}
      </div>
      <div className="flex-item fixed-size">
        <ElementInputText
          placeholder={t('component.table.search_input_placeholder')}
          value={query ?? ''}
          onUpdateValue={handleValueChange}
        />
      </div>
      <div
        className="flex-item fixed-size right">{additionalControls && (typeof additionalControls === "function" ? additionalControls({
        ...additionalParams,
        page,
        limit,
        query,
      }) : additionalControls)}</div>
    </div>}
    pagination={pagination}
  >
    {(isFetching || isLoading) &&
      <LinearProgressHolder className={classNames('horizontal-progress', {'active': true})} />}
    <ElementTable stickyFirstColumn={stickyFirstColumn}>
      {header && <thead>
      {header}
      </thead>}
      <StyledTbody state={state}>
        {data?.data?.map(rowRender)}
      </StyledTbody>
    </ElementTable>
  </LayoutTable>
}
