import ElementPageHeader                           from "$elements/ElementPageHeader";
import ElementTabs                                 from "$elements/ElementTabs";
import {ContentHolder, HeaderH1}                   from "$elements/styled";
import {selectUserRoles}                           from "$features/auth/authSlice";
import LayoutWithHeader                            from "$layouts/LayoutWIthHeader";
import ITab                                        from "$models/ITab";
import HomeRoutes                                  from "$routes/HomeRoutes";
import {formatPhone}                               from "$utils/formatters";
import * as React                                  from "react";
import {useTranslation}                            from "react-i18next";
import styled                                      from "styled-components";
import {useAppSelector}                            from "../../hooks/hooks";
import {selectCompanySlug, selectOperatorContacts} from "$features/company/companySlice";
import {selectIsContractors}                       from "$features/root/rootSlice";


const ContactsContainer = styled.div`
  & ul {
    & li {
      list-style-type: none;
    }
  }
`

export default function PageHome() {

  const {t} = useTranslation();
  const {managerStatistics, managerTicket, canCreateTicket} = useAppSelector(selectUserRoles);
  const companySlug = useAppSelector(selectCompanySlug);
  const contacts = useAppSelector(selectOperatorContacts);
  const isContractors = useAppSelector(selectIsContractors);

  const tabs: ITab[] = [
    {
      text:            t("page.home.tabs.tab_1"),
      to:              `/`,
      active:          false,
      excludeSubPaths: true,
    },
    {
      text:   t("page.home.tabs.tab_2"),
      to:     `/stats`,
      active: false,
    },
    {
      text:   t("page.home.tabs.tab_3"),
      to:     `/new-ticket`,
      active: false,
    },
  ];

  const contractorsTabs = [
    {
      text:            t("page.home.tabs.tab_1"),
      to:              `/`,
      active:          false,
      excludeSubPaths: true,
    },
    {
      text:   t("page.home.tabs.tab_2"),
      to:     `/stats`,
      active: false,
    },
  ]

  const showTabs = () => {
    const currentTabs = new Set([...tabs]);
    if (!managerStatistics) {
      const value = [...currentTabs].find(item => item.to === "/stats") as ITab;
      currentTabs.delete(value);
    }
    if (!managerTicket) {
      const value = [...currentTabs].find(item => item.to === "/") as ITab;
      currentTabs.delete(value);
    }
    if (!canCreateTicket) {
      const value = [...currentTabs].find(item => item.to === "/new-ticket") as ITab;
      currentTabs.delete(value);
    }
    return [...currentTabs];
  }

  const header = <ElementPageHeader
    title={t("page.home.title", {name: companySlug ?? ""})}
    controls={<ContactsContainer className="flex mgr-4 centered">
      <HeaderH1>{t("page.home.contacts")}</HeaderH1>
      <ul>
        {contacts?.map((item, index) => {
          return <li key={index} className="text fs-small">{item ? formatPhone(item) : ""}</li>
        })}
      </ul>
    </ContactsContainer>}
  >
    <ElementTabs tabs={!isContractors ? showTabs() : contractorsTabs} />
  </ElementPageHeader>

  return <ContentHolder>
    <LayoutWithHeader header={header}>
      <HomeRoutes />
    </LayoutWithHeader>
  </ContentHolder>
}